import { faTableLayout } from '@fortawesome/pro-regular-svg-icons';
import _sortBy from 'lodash.sortby';
import type { RouteProps } from './themesRoutes';

export const blocksRoutes = [
  {
    title: 'Overview',
    pages: [{ title: 'Mobile', slug: 'themes/blocks/overview/mobile' }],
    icon: faTableLayout,
  },
];

export const allBlocksRoutes = blocksRoutes.reduce((acc, curr: RouteProps) => {
  return [...acc, ...curr.pages];
}, []);
