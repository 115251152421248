import { Box, Flex } from '@scale/scaleui-radix';
import * as React from 'react';
import { allBlocksRoutes, blocksRoutes } from '@utils/blocksRoutes';
import { DocsPagination } from '@components/DocsPagination';
import { DocsNav } from './DocsNav';
import { CodeBlockPageWrapper } from './CodeBlockPageWrapper';
import { EditPageLink } from './EditPageLink';
import { MobileMenuProvider } from './MobileMenu';
import { SideNav } from './SideNav';
import { ThemesHeader } from './ThemesHeader';
import { ThemesMobileMenu } from './ThemesMobileMenu';

export function ThemesBlocksPage({ children }: { children: React.ReactNode }) {
  return (
    <MobileMenuProvider>
      <ThemesHeader />
      <ThemesMobileMenu />
      <Flex width="100%">
        <SideNav>
          <DocsNav routes={blocksRoutes} />
        </SideNav>
        <CodeBlockPageWrapper>
          <Box>{children}</Box>
          <DocsPagination allRoutes={allBlocksRoutes} />
          <EditPageLink />
        </CodeBlockPageWrapper>
      </Flex>
    </MobileMenuProvider>
  );
}
