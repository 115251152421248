import { Section } from '@scale/scaleui-radix';

export function CodeBlockPageWrapper({
  children,
  ...props
}: React.ComponentPropsWithoutRef<typeof Section>) {
  return (
    <Section
      className="flex-1 min-w-0"
      px={{ initial: '5', xs: '6', sm: '7', md: '9' }}
      size="4"
      {...props}
    >
      {children}
    </Section>
  );
}
