import { SideNavigation, Text, Icon } from '@scale/scaleui-radix';
import NextLink from 'next/link';
import React, { useRef, useEffect } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import { type IconLookup } from '@fortawesome/fontawesome-svg-core';
import { useCurrentPageSlug } from '@utils/useCurrentPageSlug';
import { classNames } from '@utils/classNames';
import styles from './DocsNav.module.css';

type DocsNavProps = {
  routes: {
    title: string;
    icon: IconLookup;
    pages: {
      title: string;
      slug: string;
    }[];
  }[];
};

export function DocsNav({ routes }: DocsNavProps) {
  const currentPageSlug = useCurrentPageSlug();

  return (
    <SideNavigation.Root>
      <SideNavigation.Content expandedWidth="250px" pb="9" pt="4" px="2">
        <SideNavigation.Items>
          {routes.map((page) => (
            <SideNavigation.Group
              defaultOpen
              icon={<Icon icon={page.icon} label={page.title} />}
              key={page.title}
              label={page.title}
            >
              {page.pages.map((page) => (
                <SideNavigation.Item
                  active={currentPageSlug === page.slug}
                  asChild
                  key={page.title}
                >
                  <DocsNavItem
                    active={currentPageSlug === page.slug}
                    href={page.slug}
                    key={page.slug}
                  >
                    <Text size={{ initial: '3', md: '2' }}>{page.title}</Text>
                  </DocsNavItem>
                </SideNavigation.Item>
              ))}
            </SideNavigation.Group>
          ))}
        </SideNavigation.Items>
      </SideNavigation.Content>
    </SideNavigation.Root>
  );
}

type DocsNavItemProps = {
  children: React.ReactNode;
  active?: boolean;
  disabled?: boolean;
  href: string;
  className?: string;
};

function DocsNavItem({ active, disabled, href, children, ...props }: DocsNavItemProps) {
  const className = classNames(styles.DocsNavItem, active && styles.active);
  const isExternal = href.startsWith('http');
  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    // Scroll active links into view when in a Scroll Area
    if (ref.current && active) {
      const container = document.querySelector('[data-radix-scroll-area-viewport]');

      if (!container) {
        return;
      }

      // Tread very, very, very carefully if changing this.
      // Sneaky bugs reproduced only on select cursed devices may show up.
      scrollIntoView(ref.current, {
        block: 'nearest',
        scrollMode: 'if-needed',
        boundary: (parent) => Boolean(container.contains(parent)),
        behavior: (actions) => {
          actions.forEach(({ el, top }) => {
            const dir = el.scrollTop < top ? 1 : -1;
            el.scrollTop = top + 80 * dir;
          });
        },
      });
    }
  }, [active]);

  if (disabled) {
    return (
      <span className={className} ref={ref} {...props}>
        {children}
      </span>
    );
  }

  if (isExternal) {
    return (
      <a className={className} href={href} ref={ref} rel="noopener" target="_blank" {...props}>
        {children}
      </a>
    );
  }

  return (
    <NextLink href={`/${href}`} legacyBehavior passHref>
      <a className={className} ref={ref} {...props}>
        {children}
      </a>
    </NextLink>
  );
}
