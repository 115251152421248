import { ScrollArea } from '@scale/scaleui-radix';
import { faHome, faCodePullRequest } from '@fortawesome/pro-regular-svg-icons';
import { themesRoutes } from '@utils/themesRoutes';
import { DocsNav } from './DocsNav';
import { MobileMenu } from './MobileMenu';
import { ThemesHeader } from './ThemesHeader';

export function ThemesMobileMenu() {
  return (
    <MobileMenu>
      <ThemesHeader />
      <ScrollArea>
        <DocsNav
          routes={[
            {
              title: 'Docs',
              pages: [
                {
                  title: 'Homepage',
                  slug: '',
                },
                {
                  title: 'Catalog',
                  slug: 'themes/catalog',
                },
              ],
              icon: faHome,
            },
            ...themesRoutes,
            {
              title: 'Resources',
              pages: [
                {
                  title: 'GitHub',
                  slug: 'https://github.com/radix-ui/themes',
                },
              ],
              icon: faCodePullRequest,
            },
          ]}
        />
      </ScrollArea>
    </MobileMenu>
  );
}
